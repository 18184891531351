.contact-container {
  margin-top: 8em;
  width: 100vw;
  height: max-content;
  position: relative;
  top: 0px;
  margin-bottom: 5em;
}

.contact-map{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 0;
  justify-content: space-evenly;
}

.contactDiv{
  width: 45%;
  margin: 0;
}


.contact-info{
  margin: 2em 0;
}

.contact-info h2 {
  margin: 1em 0 0;
  color: rgb(199, 0, 0);
}

.infoDiv{
  width: 100%;
}

.contact-icons{
  height: auto;
  width: 1em;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra todo el contenido horizontalmente */
  align-items: center; /* Centra todo el contenido verticalmente */
}

.phone-list{
  margin-bottom: 0.5em;
}

.email-container{
  text-align: left; 
  max-width: 600px; 
  list-style-type: none; 
}

.email-list{
  text-align: left;
  margin-bottom: 0.5em;
}

.email-info{
  text-decoration: none;
  color: black;
}

.formInputDiv{
  display: flex;
  flex-direction: column;
  width: 100%;

}

.messageForm {
  margin: 0;
  width: 100%;

}


.messageDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.messageLabel {
  margin-bottom: 0.5rem;
}
.textarea{
  box-shadow: rgba(0, 0, 0, 0.11);
  border: none;
  background: #e9e9e954;
  height: 10rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0.8em;
  font-size: 1rem;


}

.pageHeader{
  font-size: 2.5em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1em;
  width: 50%;
  color: rgb(199, 0, 0);
}

.inputLabel{
  color: #391A4C;
  text-align: left;
}


a{
  text-decoration: none;
}

.messageForm .btn-orange {
  font-size: 1em;
}

.formInput{
  background-color:#e9e9e954;
  border: none;
  padding: 0.8em;
  font-family: 'Montserrat', sans-serif;
}

.map-container {
  width: 50vw;
  height: 53vh;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}


@media (max-width: 1000px) {
  .contact-map {
    flex-direction: column;
  }

  .contactDiv {
    width: 90vw;
    margin-bottom: 3em;
  }

  .map-container {
    width: 90vw;
    height: 50vh;
    margin-bottom: 2em;
  }


}
@media (max-width: 700px) {

  .contact-map{
    flex-direction: column;
  }

  .contactDiv {
    width: 90vw;
    margin-bottom: 3em;
  }

  .infoDiv{
    display: block;
    width:100%;
  }

  .formInputDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .primaryButton{
    margin: auto;
    width: 100%;
  }

  .pageHeader {
    font-size: 1.7rem;
  }

  .map-container {
    width: 90vw;
    height: 50vh;
    margin-bottom: 2em;
  }

}
