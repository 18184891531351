@import url('https://fonts.googleapis.com/css2?family=Poppins:400;500;600;700;800;&display=swap');
:root {
  --my-orange: #ed9b1f;
  width: 100vw;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  font-family: 'poppins', sans-serif;
}



.App{
  text-align: center;
}

.btn-orange {
  border-radius: 0.2rem;
  background-color: initial;
  background-image: linear-gradient(-180deg, #ED751F, #E62C03);
  color: white;
  padding: 0.4rem 1.2rem;
  transition: all .2s;
  border: none;
}

.btn-orange:hover {
  box-shadow: rgba(51, 15, 0, 0.5) 0 3px 8px;
  background-image: linear-gradient(-180deg, #ed9b1f, #E62C03);
}
