
.navbar{
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 2px 10px 0 rgba(207, 207, 207, 0.599);
  width: 100vw;
  height: 130px;
  padding: 0.7rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  max-width: 100vw;
}

.navbar-logo{
  margin-left: 1em;
  height: 8em;
  width: 14em;
  cursor: default;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: start;

}



.nav-links {
  text-decoration: none;
  border: none;
  font-size: 1.3rem;
  font-weight: 400;
  color: #222;
  outline: none;
  position: relative;
  padding: 0.5rem;
  cursor: default;
}

.nav-call{
  color: #9A9C9D;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0.5rem;
  cursor: default;
}



.nav-call i {
  padding-right: 10px;
}


.nav-links:before,
.nav-links:after {
  transition: all 0.2s ease-in-out;
  content: '';
  height: 0;
  position: absolute;
  width: 24px;
}

.nav-links:hover:before,
.nav-links:hover:after {
  width: 100%;
  border-bottom: 3px solid var(--my-orange);
  bottom: 0px;
  left: 0px;
}

.nav-links:hover{
  color: var(--my-orange);
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;
}


.menu-icons{
  display: none;
}


@media screen and (max-width:850px) {

  .navbar-logo {
      margin-left: 0;
    }
  .navbar-more{
    font-size: 1.5rem;
    margin-right: 5px;
  }

  .menu-icons {
    display: block;
  }

  .menu-icons i{
    font-size: 2rem;
  }

  .navbar {
      z-index: 99;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-column-gap: 5px;
      justify-items: center;
    }

  .navbar-logo:nth-child(1) {
    grid-column-start: 2;
  }

  .menu-icons:nth-child(2) {
    margin-left: auto;
  }

  .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: white;
      width: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      top: 0;
      opacity: 0;
      align-items: stretch;
      padding: 120px 0 30px 0;
      margin: 0;
    }

    .nav-menu.active{
      left: 0;
      opacity: 1;
      z-index: -1;
    }

    #nav-link-one{
      border-top: 2px solid rgba(0, 0, 0, 0.068);
    }

    .nav-links{
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 2rem 0;
    }

    .nav-links:hover{
      background-color: rgba(185, 185, 185, 0.091);
      transition: all 0.2s ease-in-out;
    }

    .btn-orange:hover {
      border-radius: 0.3rem;
      background-color: #ed751fdc;
      color: white;
    }



}
