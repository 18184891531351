.hero{
  width: 100%;
  height: 101%;
  position: relative;
  top: 120px;
}

.hero-bg{
  width: 100vw;
  height: calc(100vh - 120px);
  overflow: hidden;
}

.hero-img{
  background-size: cover;
  background-image: url('../assets/broker.jpg');
  margin: 0;
  width: 100%;
  height: 100%;
}

.hero-text{
  position: absolute;
  top: 25%;
  left: 37%;
  color: white;
  width: 30%;
  background: radial-gradient(circle, rgba(2, 0, 36, 0.4739726027397261) 0%, rgba(0, 0, 0, 0.35342465753424657) 35%, rgba(0, 0, 0, 0.30410958904109586) 50%, rgba(0, 0, 0, 0.016) 60%);
}

.hero-text h1{
  font-size: 2rem;
  font-weight: 700;
  background-color: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color:transparent;
}

.hero-text p {
  font-size: 1.3rem;
  color: white;
  padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
  text-decoration: none;
  background-color: initial;
  background-image: linear-gradient(-180deg, #ED751F, #E62C03);
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  transition: all .2s;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
}

.hero-text .show:hover {
  box-shadow: rgba(51, 15, 0, 0.5) 0 3px 8px;
  background-image: linear-gradient(-180deg, #ed9b1f, #E62C03);
}

.hero-anagrama {
  height: 11em;
  width: 12em;
  cursor: pointer;
  margin-bottom: 1em;
}

@media screen and (max-width:850px) {

  .hero-text {
    top: 30%;
    left: 22%;
    width: 60%;
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 0.4739726027397261) 0%, rgba(0, 0, 0, 0.35342465753424657) 35%, rgba(0, 0, 0, 0.30410958904109586) 50%, rgba(0, 0, 0, 0) 60%);
  }

  .hero-img {

    background-image: url('../assets/hero_image_cell.JPG');
    margin: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hero-anagrama {
    height: 9em;
    width: 10em;
    margin-bottom: 0.2em;
  }

  .hero-text h1{
    font-size: 2rem;
    font-weight: 700;
  }

  .hero-text p {
      font-size: 1.3rem;
      color: rgb(255, 255, 255);
      margin: 0 0 1em 0;
  }

}

@media screen and (max-width:500px) {



  .hero-img {
    background-size:  cover;
  }

  .hero-text {
    top: 27%;
    left: 22%;
    width: 60%;
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 0.4739726027397261) 0%, rgba(0, 0, 0, 0.35342465753424657) 35%, rgba(0, 0, 0, 0.30410958904109586) 50%, rgba(0, 0, 0, 0) 60%);
  }

  .hero-anagrama {
    height: 8em;
    width: 9em;
    margin-bottom: 1em;
  }

  .hero-text h1 {
    font-size: 1.7rem;
    font-weight: 700;
  }

  .hero-text p {
    font-size: 1.3rem;
    color: white;
    width: 98%;
    text-align: center;
  }

}

/* smoke part */
.smoke-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.smoke,
.smoke2,
.smoke3 {
  filter: blur(7px);
  transform-origin: 50% 50%;
}

.smoke {
  animation: smoke1 3s linear infinite;
  animation-delay: 0.5s;
}

.smoke2 {
  animation: smoke2 3s linear infinite;
  animation-delay: 1.5s;
}

.smoke3 {
  width: 700px;
  animation: smoke3 4s linear infinite;
  animation-delay: 2.5s;
}

@keyframes smoke1 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(-1, 1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(-1, 1.05);
    opacity: 0.15;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(-1, 1.1);
    opacity: 0.3;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(-1, 1.15);
    opacity: 0.15;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(-1, 1.2);
    opacity: 0;
  }
}

@keyframes smoke2 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(1.05);
    opacity: 0.15;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(1.1);
    opacity: 0.3;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(1.15);
    opacity: 0.15;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(1.2);
    opacity: 0;
  }
}

@keyframes smoke3 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-20px) scale(1.05);
    opacity: 0.15;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-40px) scale(1.1);
    opacity: 0.3;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-60px) scale(1.15);
    opacity: 0.15;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-80px) scale(1.2);
    opacity: 0;
  }
}
