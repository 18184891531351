.companies-container {
  padding: 3rem;
  width: 100vw;
  height: max-content;
  position: relative;
  top: 100px;
  padding-bottom: 8em;
  background-color: var(--my-orange);
}

.companies-container h1{
  font-weight: 600;
  text-align: center;
  margin: 0 0 2em 0;
  color: white;
  font-size: 2.5em;
}

.companies-div {
  margin: 3em 2em 0;
  display: grid;
  grid-template-columns: repeat(6, minmax(10px, 1fr));
  grid-gap: 10px;
  row-gap: 20px;
  justify-items: center;

}

.companies-div div{
  background-color: rgba(255, 255, 255, 0.429);
  width: 100%;
  border: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.company-logo{

  border-radius: 5px;
  padding: 1rem;
}

.logo1{
  width: 12em;
  height: 5em;
}
.logo2 {
  width: 12em;
  height: 4em;
}
.logo3 {
  width: 8em;
  height: 6em;
}



@media screen and (max-width:1250px) {

  .companies-div {
    grid-template-columns: repeat(4, minmax(10px, 1fr));
  }
}

@media screen and (max-width:850px) {

  .companies-div {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }
}

@media screen and (max-width:750px) {

  .companies-div {
      margin: 0 1em;
      display: grid;
      grid-template-columns: repeat(3, minmax(10px, 1fr));
      grid-gap: 10px;
      row-gap: 20px;
      justify-items: center;
  }

    .logo1 {
      width: 9em;
      height: 5em;
    }

    .logo2 {
      width: 9em;
      height: 5em;
    }

    .logo3 {
      width: 7em;
      height: 5em;
    }

}

@media screen and (max-width:550px) {

  .companies-container {
    width: 100vw;
    height: max-content;
    padding-bottom: 8em;
  }

  .companies-container h1 {
    width: 95%;
    margin: 1em auto;
  }

    .companies-div {
      margin: 0 1em;
      display: grid;
      grid-template-columns: repeat(2, minmax(10px, 1fr));
      grid-gap: 10px;
      row-gap: 20px;
      justify-items: center;
    }



    .logo1 {
      width: 9em;
      height: 5em;
    }

    .logo2 {
      width: 9em;
      height: 4em;
    }

    .logo3 {
      width: 7em;
      height: 5em;
    }

    .gradiant-orange {
      margin-top: 2em;
    }
}
