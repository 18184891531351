.gallery-container{
  margin-top: 6em;
  width: 100vw;
  height: max-content;
  position: relative;
  top: 0px;

}

.gallery-header {
  text-align: center;
  margin: 1em auto 1em;
  color: #2f4e24;
}

.gallery-columns {
  display: grid;
  grid-template-columns: repeat(4, 250px);
  row-gap: 5px;
  gap: 5px;

  margin: 0 10%;
  align-items: center;
  justify-content: center;
}

.gallery-columns img{
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 3px;
}

.gallery-text {
  width: 45vw;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
}

.gallery-heading {
  font-weight: 600;
  text-align: center;
  margin: 1.5em 0 1em 0;
  color: #ca3600;
  font-size: 2.5em;
}

.gallery-text p {
  text-align: justify;
}

.gallery-carousel{
  height: 52vh;
  margin-top: 10px;
}

.gallery-carousel p{
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 10px;
  text-align: center;
  z-index: 2;
  color: rgba(0, 0, 0, 0.655);
}

.gallery-carousel img {

  width: auto;
  height: 80%;
  object-fit: contain;
}

.gallery-carousel-close{
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.599);
}

.carousel .thumb {
  width: 50px;
  height: 50px;
  margin: 5px;
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.carousel .thumb img {
  height: 41px;
  object-fit: cover;
}
.carousel .thumb.selected {
  border-color: #007bff;
}

@media screen and (max-width:1150px) {
  .gallery-columns {
    grid-template-columns: repeat(3, 250px);
    gap: 10px;
  }

}

@media screen and (max-width:850px) {
  .gallery-columns {
    grid-template-columns: repeat(2, 250px);
    gap: 20px;
  }

}

@media screen and (max-width:850px) {

  .gallery-heading{
    font-size: 2rem;
  }
  .gallery-columns {
    grid-template-columns: repeat(2, 200px);
    gap: 5px;
  }

  .gallery-columns img{
    height: 200px;
  }

}

@media screen and (max-width:500px){

  .gallery-carousel{
    height: 55vh;
  }

  .gallery-columns {
    grid-template-columns: repeat(2, 160px);
    gap: 5px;
  }

  .gallery-columns img {
    height: 160px;
  }
}
