.about-container {
  width: 100vw;
  height: 94vh;
  position: relative;
  top: 123px
}

.about-text{
  width: 45vw;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
}

.about-text h1 {
  font-weight: 600;
  text-align: center;
  margin: 1.5em 0 1em 0;
  color: #ca3600;
  font-size: 2.5em;
}

.about-text p {
  text-align: justify;
}

.text-img{
  display: grid;
  align-items: center;
  height: calc(100% - 170px);
  grid-template-columns: 56vw 44vw;
}

.about-img{
  width: 44vw;
  height: 83.5vh;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  cursor: default;
}

.about-img-activo {
  width: 43vw;
  height: 90vh;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  cursor: default;
}



@media screen and (max-width:800px) {

  .about-text {
    width: 95vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .about-text p {
      text-align: justify;
      font-size: 1em;
      margin-bottom: 2em;
    }

  .text-img {
      margin: 2em auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
  }



  .about-text h1 {
    font-weight: 600;
    text-align: center;
    margin: 1.5em 0 1em;
    color: #ca3600;
    font-size: 2.5em;
  }

  .about-img {
    background-image: url('../assets/img_about.jpeg');
    background-size: 100% 99%;
    width: 70vw;
    height: 33vh;
    clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
    cursor: default;
    transition: all 1s;
    position: unset;
    margin-bottom: 1em;

  }

    .about-img-activo {
      background-image: url('../assets/img_about.jpeg');
      background-size: 100% 99%;
      width: 70vw;
      height: 33vh;
      clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
      cursor: default;
      transition: all 1s;
      position: unset;
      margin-bottom: 1em;
    }
}

@media screen and (max-width:500px) {

  .about-container{
    width: 100vw;
    height: auto;
    position: relative;
    top: 65px;
  }

  .about-text {
    height: auto;
    cursor: pointer;
    }

  .about-text p {
    text-align: justify;
    font-size: 1em;
    margin: 0 1em 3em
  }

  .text-img {
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }



  .about-text h1 {
    font-weight: 600;
    text-align: center;
    margin: 1.5em 0 1em;
    color: #ca3600;
    font-size: 2.5em;
  }

  .about-img {
    background-image: url('../assets/img_about.jpeg');
    background-size: cover;
    width: 90vw;
    height: 30vh;
    clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
    cursor: default;
    transition: all 1s;
    position: unset;
    margin-bottom: 1em;

  }

  .about-img-activo {
    background-image: url('../assets/img_about.jpeg');
    background-size: cover;
    width: 90vw;
    height: 30vh;
    clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
    cursor: default;
    transition: all 1s;
    position: unset;
    margin-bottom: 1em;
  }
}
