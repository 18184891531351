.footer {
  background: #ca3600;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 150px;
  padding: 0px 1em;
  color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-items: center;
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.footer-link  {
  cursor: pointer;
}

.footer-link a{
  text-decoration: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
}

.footer-link svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.icon-size {
  width: 70;
  height: 70;
}

.footer-copyright{

  color: white;
  text-align: end;
}

.footer-logo{
  height: 100px;
  width: 160px;
  cursor: default;
}

.footer-dir{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

}

.footer-dir h2 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0.5em;

}
.footer-dir p {
  width: 90%;
}

@media screen and (max-width:500px) {

  .icon-size{
    width: 45;
    height: 45;
  }
}
